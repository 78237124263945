import { lazy } from 'react';
import RolesGuard from 'src/components/RolesGuard';

export const Dashboards = [
    {
        exact: true,
        path: '/reports/dashboard',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
            'carone',
            'receptionist',
        ],
        component: lazy(() => import('src/views/reports/DashboardView')),
    },
    {
        exact: true,
        path: '/reports/appointments',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/reports/AppointmentsView')),
    },
    {
        exact: true,
        path: '/reports/visits',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'user',
            'general manager',
            'sales manager',
        ],
        component: lazy(() => import('src/views/reports/VisitsView')),
    },
    {
        exact: true,

        path: '/reports/sales',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'general manager',
            'sales manager',
            'admin',
            'marketing',
        ],
        component: lazy(() => import('src/views/reports/FinancingView')),
    },
];

export const Drilldowns = [
    {
        exact: true,
        path: '/drilldown/recordings',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(
            () => import('src/views/chartLeads/DrilldownRecordings')
        ),
    },

    {
        exact: true,
        path: '/drilldown/leads',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/chartLeads/DrilldownLeads')),
    },
    {
        exact: true,
        path: '/drilldown/models',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'general manager',
            'sales manager',
            'rh',
            'admin',
            'marketing',
        ],
        component: lazy(() => import('src/views/chartLeads/DrilldownModels')),
    },
    {
        exact: true,
        path: '/drilldown/pending-leads',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(
            () => import('src/views/chartLeads/DrilldownPendingLeads')
        ),
    },
    {
        exact: true,
        path: '/drilldown/visits',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/DrilldownVisits')),
    },
    {
        exact: true,
        path: '/drilldown/score',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/DrilldownBureau')),
    },
    {
        exact: true,
        path: '/drilldown/sources',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'general manager',
            'sales manager',
            'rh',
            'admin',
            'marketing',
        ],
        component: lazy(() => import('src/views/chartLeads/DrilldownSources')),
    },
    {
        exact: true,
        path: '/drilldown/calls',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'general manager',
            'sales manager',
            'admin',
            'rh',
            'marketing',
        ],
        component: lazy(() => import('src/views/chartLeads/DrilldownCalls')),
    },
    {
        exact: true,
        path: '/drilldown/modelsource',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'general manager',
            'sales manager',
            'rh',
            'admin',
            'marketing',
        ],
        component: lazy(
            () => import('src/views/chartLeads/DrilldownModelSource')
        ),
    },
];

export const Charts = [
    {
        exact: true,
        path: '/reviews/recordings',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'user',
            'general manager',
            'sales manager',
        ],
        component: lazy(() =>
            import('src/views/chartLeads/UserCallPerformance').then(
                (module) => ({ default: module.UserCallPerformance })
            )
        ),
    },
    {
        exact: true,
        path: '/extra/highcharts/monthly',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/monthlyHighchart')),
    },
    {
        exact: true,
        path: '/extra/highcharts/score',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/scoreHighchart')),
    },
    {
        exact: true,
        path: '/extra/highcharts/sales-time',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/saleTimeHighchart')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'user',
            'general manager',
            'sales manager',
            'marketing',
        ],
        path: '/extra/highcharts/modelsTable',
        component: lazy(() => import('src/views/chartLeads/ModelsProspects')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'user',
            'general manager',
            'sales manager',
            'marketing',
            'digital marketing',
        ],
        path: '/extra/highcharts/sourcesTable',
        component: lazy(() => import('src/views/chartLeads/SourcesProspects')),
    },

    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'user',
            'general manager',
            'sales manager',
            'marketing',
        ],
        path: '/extra/highcharts/agentsSourcesTable',
        component: lazy(
            () => import('src/views/chartLeads/SourcesAgentComparative')
        ),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'user',
            'general manager',
            'sales manager',
            'marketing',
        ],
        path: '/extra/highcharts/agenciesTable',
        component: lazy(() => import('src/views/chartLeads/AgenciesProspects')),
    },

    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'user',
            'general manager',
            'sales manager',
            'marketing',
            'digital marketing',
        ],
        path: '/extra/highcharts/agentsTable',
        component: lazy(() => import('src/views/chartLeads/AgentsComparative')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: ['rockstar'],
        path: '/extra/highcharts/addons-report',
        component: lazy(() => import('src/views/charts/AddonsReport')),
    },
    {
        exact: true,
        path: '/extra/highcharts/unit-comparative',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/unitComparative')),
    },

    {
        exact: true,
        path: '/extra/highcharts/unit-comparative-extra',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/unitComparative2')),
    },
    {
        exact: true,
        path: '/extra/highcharts/unit-comparative-multiple',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(
            () => import('src/views/chartLeads/UnitComparativeMultiple')
        ),
    },
    {
        exact: true,
        path: '/extra/highcharts/time',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'general manager',
            'sales manager',
            'admin',
            'rh',
            'marketing',
        ],
        component: lazy(() => import('src/views/chartLeads/ByTime')),
    },
    {
        exact: true,
        path: '/extra/highcharts/agentsStatus',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/agentsStatus')),
    },
    {
        exact: true,
        path: '/extra/highcharts/temperatures',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/temperaturesChart')),
    },
    {
        exact: true,
        path: '/extra/highcharts/funnel',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(
            () => import('src/views/chartLeads/temperaturesChartRSI')
        ),
    },
    {
        exact: true,
        path: '/extra/highcharts/temperaturesComparative',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'general manager',
            'sales manager',
            'admin',
            'rh',
            'marketing',
        ],
        component: lazy(
            () => import('src/views/chartLeads/temperaturesComparative')
        ),
    },

    {
        exact: true,
        path: '/extra/highcharts/vs',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/vsBarHighchart')),
    },
    {
        exact: true,
        path: '/extra/highcharts/daily',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/dailyHighchart')),
    },

    {
        exact: true,
        path: '/extra/highcharts/hours',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/hoursHighchart')),
    },
    {
        exact: true,
        path: '/extra/highcharts/agentsCalls',

        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/AgentsCalls')),
    },
    {
        exact: true,
        path: '/extra/highcharts/agentsLeads',

        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/AgentsLeads')),
    },
    {
        exact: true,
        path: '/extra/highcharts/stores-extra-scores',

        guard: RolesGuard,
        roles: ['rockstar'],
        component: lazy(() => import('src/views/chartLeads/storesExtraScores')),
    },
    {
        exact: true,
        path: '/extra/highcharts/stores-scores',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/storesScores')),
    },
    {
        exact: true,
        path: '/extra/highcharts/visits',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/visitsHighchart')),
    },
    {
        exact: true,
        path: '/extra/highcharts/recordings-reviews',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'admin',
            'general manager',
            'sales manager',
            'rh',
            'marketing',
        ],
        component: lazy(() => import('src/views/chartLeads/recordingsReviews')),
    },
    {
        exact: true,
        path: '/extra/highcharts/appointments',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
            'adminBDC',
        ],
        component: lazy(
            () => import('src/views/chartLeads/appointmentsHighchart')
        ),
    },
    {
        exact: true,
        path: '/extra/highcharts/hours-comparative',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
        ],
        component: lazy(() => import('src/views/chartLeads/hoursComparative')),
    },
    {
        exact: true,
        path: '/extra/highcharts/status-report',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/pyramidHighchart')),
    },
    {
        exact: true,
        path: '/extra/highcharts/totalTemperatures',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'admin',
            'general manager',
            'sales manager',
            'rh',
            'marketing',
        ],
        component: lazy(() => import('src/views/chartLeads/totalTemperatures')),
    },
    {
        exact: true,
        path: '/extra/highcharts/monitoringAgents',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'admin',
            'general manager',
            'sales manager',
            'rh',
            'marketing',
        ],
        component: lazy(() => import('src/views/chartLeads/monitoringAgents')),
    },
    {
        exact: true,
        path: '/extra/highcharts/all',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/allHighchart')),
    },
    {
        exact: true,
        path: '/extra/highcharts/captacion-medios',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(
            () => import('src/views/chartLeads/captacionMediosHighchart')
        ),
    },
    {
        exact: true,
        path: '/extra/highcharts/models',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/modelsHighchart')),
    },
    {
        exact: true,
        path: '/extra/highcharts/multiple',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
        ],
        component: lazy(() => import('src/views/chartLeads/multiBarHighchart')),
    },
    {
        exact: true,
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'admin',
            'general manager',
            'sales manager',
            'rh',
            'marketing',
        ],
        path: '/reports/closures',
        component: lazy(() => import('src/views/chartLeads/reportSales')),
    },
    {
        exact: true,
        path: '/reports/pending-tasks',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/taskStoreChart')),
    },

    {
        exact: true,
        path: '/reports/visits-leads',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'admin',
            'super admin',
            'marketing',
            'digital marketing',
            'general manager',
            'sales manager',
            'rh',
        ],
        component: lazy(() => import('src/views/chartLeads/VisitsReport')),
    },
    {
        exact: true,
        path: '/reports/kraquen',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'general manager',
            'marketing',
            'digital marketing',
            'admin',
        ],
        component: lazy(() => import('src/views/chartLeads/matrix')),
    },
    {
        exact: true,
        path: '/reports/matrixInvestment',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'general manager',
            'marketing',
            'digital marketing',
            'admin',
        ],
        component: lazy(() => import('src/views/chartLeads/matrixInvestment')),
    },
    {
        exact: true,
        path: '/reports/matrixSegmentation',
        guard: RolesGuard,
        roles: [
            'rockstar',
            'super admin',
            'general manager',
            'marketing',
            'digital marketing',
            'admin',
        ],
        component: lazy(
            () => import('src/views/chartLeads/matrixSegmentation')
        ),
    },
    {
        exact: true,
        path: '/reports/score-report',
        guard: RolesGuard,
        roles: ['rockstar', 'super admin', 'general manager'],
        component: lazy(() => import('src/views/chartLeads/ScoreReport')),
    },
];

export const InvestmentChart = [
    {
        exact: true,
        path: '/create/investment-chart',
        guard: RolesGuard,
        roles: ['rockstar', 'digital marketing'],
        component: lazy(
            () => import('src/views/InvestmentChart/ChartCreateView')
        ),
    },
    {
        exact: true,
        path: '/management/investments-chart/:id',
        guard: RolesGuard,
        roles: ['rockstar', 'digital marketing'],
        component: lazy(
            () => import('src/views/InvestmentChart/ChartDetailsView')
        ),
    },
    {
        exact: true,
        path: '/management/investments-chart/:id/edit',
        guard: RolesGuard,
        roles: ['rockstar', 'digital marketing'],
        component: lazy(
            () => import('src/views/InvestmentChart/ChartEditView')
        ),
    },
    {
        exact: true,
        path: '/management/investments-chart',
        guard: RolesGuard,
        roles: ['rockstar', 'digital marketing'],
        component: lazy(
            () => import('src/views/InvestmentChart/ChartListView')
        ),
    },
];
